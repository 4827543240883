<template>
  <div class="warn-tip">
    <img
        v-if="tipIcon"
        src="../assets/img/warn-tip.png"
        class="warn-tip-icon"
    />
    <div class="warn-tip-content">
      {{ content }}
    </div>

    <van-button
        v-if="click"
        class="warn-tip-btn"
        plain
        success
        @click="click"
        color="#E4B123"
        :loading="loading"
    >{{ btnText }}</van-button
    >
  </div>
</template>
<script>
export default {
  name: "WarnTip",
  props: {
    tipIcon: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "tip",
    },
    click: {
      type: Function,
    },
    btnText: {
      type: String,
      default: "tip",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.warn-tip {
  display: flex;
  align-items: center;
  background: #FFF9E7;
  border-radius: 20px;
  opacity: 1;
  padding: 26px 38px;
}
.warn-tip-icon {
  width: 58px;
  height: 52px;
  margin-right: 19px;
}

.warn-tip-content {
  color: #E4B123;
  font-size: 24px;
  font-weight: 500;
}

.warn-tip-btn {
  font-family: Microsoft YaHei;
  width: 242px;
  height: 72px;
  font-size: 28px;
  margin-left: 29px;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 400;
}
</style>
