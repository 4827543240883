<template>
  <div class="tips-box">
    <p class="tips-title">
      {{ $t("tip-title") }}
    </p>
    <div class="tips-content"
        v-html="content"
    >
    </div>
  </div>
</template>
<script>
import {AdExplain, OrderExplain, RegisterExplain} from "../api/httpApi";

export default {
  name: "Tips",
  props: {
    type: String
  },
  data() {
    return {
      content: ""
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      let func
      switch (this.type) {
        case "register":
          func = RegisterExplain
          break
        case "order":
          func = OrderExplain
          break
        case "ad":
          func = AdExplain
          break
      }
      if (!func) {
        return false
      }
      func()
          .then(res => {
            if (res.status !== 1) {
              return false
            }
            this.content = res.data
          })
    }
  }
};
</script>

<style scoped>

.tips-box {
  margin: 0 30px;
}

.tips-title {
  font-size: 24px;
  font-weight: 400;
  color: #333333;
}
.tips-content {
  margin-top: 16px;
  margin-left: 3px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
}

</style>