<template>
  <div class="box">
    <div class="header-warn-tip-box">
      <WarnTip v-if="isWarnTipHeader" tipIcon :content="warnTipHeader"/>
    </div>

    <div class="body">
      <div class="order-header">
        <div class="order-header-title">
          {{ statusText }}
        </div>
        <div class="order-header-content">
          {{ timeText }}：
          <my-count-down ref="myCountDown" type="m" :end-time="endTime" @clearTimer="clearTimer"></my-count-down>
          <img class="order-header-message" @click="messageClick" src="../../assets/img/message.png"/>
        </div>

      </div>

      <div class="order-info">

        <div class="order-info-item">

          <div class="order-info-label">商户地址</div>
          <div class="order-info-content">
            <div>{{ address | strHidden(13, 5, 4) }}</div>
            <MyCopy :text="address || ''"/>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">订单号</div>
          <div class="order-info-content">
            <div>{{ info.order.id }}</div>
            <MyCopy :text="info.order.id || ''"/>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">下单时间</div>
          <div class="order-info-content">
            <div>{{ info.order.createTimestamp | parseTime }}</div>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">付款时间</div>
          <div class="order-info-content">
            <div>{{ info.order.payTimestamp | parseTime }}</div>
          </div>

        </div>

        <div class="order-info-item" v-if="status === 3">

          <div class="order-info-label">完成时间</div>
          <div class="order-info-content">
            <div>{{ info.order.endTimestamp | parseTime }}</div>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">购买数量</div>
          <div class="order-info-content">
            <div>{{ info.order.amount.div(usdtDecimals).decimalPlaces(2) }} {{ httpInfo.CoinName }}</div>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">购买价格</div>
          <div class="order-info-content">
            <div>{{ info.order.price.div(usdtDecimals).decimalPlaces(2) }} {{ httpInfo.CurrencyName }}</div>
          </div>

        </div>

        <div class="order-info-item">

          <div class="order-info-label">付款总额</div>
          <div class="order-info-content">
            <div class="order-info-content-money">{{ info.order.amount.div(usdtDecimals).times(info.order.price).div(usdtDecimals).decimalPlaces(2) }} {{ httpInfo.CurrencyName }}</div>
          </div>

        </div>

        <div class="order-info-item" style="justify-content: flex-start">

          <div class="order-info-label">商户留言</div>
          <div class="order-info-content">
            <div>
              <my-text-overflow v-if="httpInfo.Remark" :text="httpInfo.Remark" text-class="order-info-content-remark" :maxLines="3">
                <template v-slot:default="{ clickToggle, expanded }">
                  <div class="order-info-content-remark-text-overflow" @click="clickToggle">
                    {{ expanded ? "-收起" : "+展开" }}
                  </div>
                </template>
              </my-text-overflow>
            </div>
          </div>

        </div>
      </div>

      <div class="center-warn-tip" v-if="isAppeal">
        <WarnTip
            :content="warnTip"
            :click="complaintOrder"
            :btnText="'立即投诉'"
            :loading="complaintLoaing"
        />
      </div>

      <div class="center-btn-box" v-if="isCancel">
        <van-button
            class="center-btn"
            plain
            success
            color="#FFFFFF"
            @click="cancel"
            :loading="cancelLoading"
        >取消订单
        </van-button>
      </div>

      <div class="center-btn-box" v-if="isReOrder">
        <van-button
            class="center-btn"
            plain
            success
            color="#FFFFFF"
            @click="reOrderClick"
        >重新下单
        </van-button>
      </div>

      <div class="center-btn-box" v-if="isBuy">
        <van-button
            class="center-btn"
            plain
            success
            color="#FFFFFF"
            @click="buyClick"
        >{{ buyText }}
        </van-button>
      </div>

      <div class="center-btn-box" v-if="isPay || payLoading">
        <van-button
            v-if="info.order.status === 1"
            :loading="cancelLoading"
            class="center-btn center-btn-plain center-btn242 center-btn-m27"
            plain
            success
            @click="cancel"
            color="#3665B7"
        >取消订单
        </van-button>
        <van-button
            class="center-btn center-btn400"
            @click="payClick"
            :loading="payLoading"
            plain
            success
            color="#FFFFFF"
        >{{ payText }}
        </van-button>
      </div>

      <tips type="order"></tips>

    </div>

    <van-dialog v-model:show="appealShow" title="申诉" show-cancel-button>

      <div class="appeal-box">
        <div class="appeal-item">订单已提交申诉, 请联系客服提交证据, 以帮助客服进行仲裁</div>
        <div class="appeal-item">
          <div class="appeal-item-title">Telegram</div>
          <div class="appeal-item-content">
            <span>{{systemSetting.Telegram}}</span>
            <MyCopy :text="systemSetting.Telegram"/>
          </div>
        </div>
        <div class="appeal-item">
          <div class="appeal-item-title">Email</div>
          <div class="appeal-item-content">
            <span>{{systemSetting.EMail}}</span>
            <MyCopy :text="systemSetting.EMail"/>
          </div>
        </div>
        <div class="appeal-item">
          <div class="appeal-item-title">Session</div>
          <div class="appeal-item-content">
            <span class="appeal-item-content-link" @click="sessionClick">{{systemSetting.SessionId | strHidden(13, 5, 4)}}</span>
            <MyCopy :text="systemSetting.SessionId"/>
          </div>
        </div>
      </div>

    </van-dialog>

  </div>
</template>

<script>
import Tips from "../../components/Tips.vue";
import WarnTip from "../../components/WarnTip.vue";
import MyCopy from "../../components/MyCopy.vue";
import MyCountDown from "../../components/my-count-down";
import {CancelOrder, ComplaintOrder, ConfirmPay, ConfirmReceive, OrderInfo} from "../../api/otc";
import BigNumber from "bignumber.js";
import {
  MemberCancelOrder, MemberComplaintOrder,
  MembersCollection,
  MembersTransfer, MerchantCancelOrder,
  MerchantCollection, MerchantComplaintOrder,
  MerchantTransfer,
  OrderDetails
} from "../../api/httpApi";
import {getAccounts} from "../../utils/wallet";
import {mapGetters} from "vuex";
import {isApp, sessionChat} from "../../api/app";
import MyTextOverflow from "../../components/my-text-overflow";

export default {
  name: "OrderDetail",
  components: {Tips, WarnTip, MyCopy, MyCountDown, MyTextOverflow},
  computed: {
    ...mapGetters(["systemSetting", "protocol"]),
    isTrader() {
      // return this.info.order.owner.toLowerCase() !== this.account.toLowerCase()
      return this.source !== "user"
    },
    addressText() {
      if (this.isTrader) {
        return "用户地址"
      }
      return "商户地址"
    },
    address() {
      if (this.isTrader) {
        return this.info.order.owner
      }
      return this.info.order.traderAddress
    },
    endTime() {
      if (this.info.order.status !== 1 && this.info.order.status !== 2) {
        return 0
      }
      if (this.info.order.status === 1) {
        return this.info.order.createTimestamp + this.info.timeOut
      }
      return this.info.order.payTimestamp + this.info.timeOut
    },
    timeText() {
      if (this.status === 1) {
        return "付款时间"
      }
      return "确认时间"
    },
    isWarnTipHeader() {
      return this.status === 1 || this.status === 2
    },
    warnTipHeader() {
      const orderType = this.info.order.orderType
      let name = ""
      if (orderType === 0) {
        // 卖单
        if (!this.isTrader) {
          // 用户
          // return this.status === 1
          if (this.status === 1) {
            name = "商户"
            return `${name}将在30分钟内完成付款; ${name}确认收款后合约自动释放Token; 若${name}长时间未处理订单, 请及时联系${name}.`
          } else {
            return `请在30分钟内向卖家完成转账,并点击我已付款; 30分钟为安全交易时长, 为避免造成不必要的损失, 超时后请取消订单.`
          }
        } else {
          // 商户
          // return this.status === 2
          if (this.status === 2) {
            name = "用户"
            return `${name}将在30分钟内确认收款; ${name}确认收款后合约自动释放Token; 若${name}长时间未处理订单, 请及时联系${name}.`
          } else {
            return `请在30分钟内向用户完成转账,并点击我已付款; 30分钟为安全交易时长, 为避免造成不必要的损失, 超时后请取消订单.`
          }
        }
      } else {
        // 买单
        if (this.isTrader) {
          // 商户
          // return this.status === 1
          if (this.status === 1) {
            name = "用户"
            return `${name}将在30分钟内完成付款; ${name}确认收款后合约自动释放Token; 若${name}长时间未处理订单, 请及时联系${name}.`
          } else {
            return `请在30分钟内确认收款; 确认收款后合约自动释放Token; 若长时间未处理订单, 可能导致订单被冻结并降低成单率.`
          }
        } else {
          // 用户
          // return this.status === 2
          if (this.status === 2) {
            name = "商户"
            return `${name}将在30分钟内确认收款; ${name}确认收款后合约自动释放Token; 若${name}长时间未处理订单, 请及时联系${name}.`
          } else {
            return `请在30分钟内向卖家完成转账,并点击我已付款; 30分钟为安全交易时长, 为避免造成不必要的损失, 超时后请取消订单.`
          }
        }
      }
    },
    isBuy() {
      if (this.isTrader) {
        // 商户不显示
        return false
      }
      return this.status === 3
    },
    buyText() {
      const orderType = this.info.order.orderType
      if (this.isTrader) {
        // 商户
        return orderType === 0 ? "继续购买" : "继续出售"
      } else {
        // 用户
        return orderType === 0 ? "继续出售" : "继续购买"
      }
    },
    isReOrder() {
      return !this.isTrader && this.status === 0
    },
    isPay() {
      const orderType = this.info.order.orderType
      if (this.isTrader) {
        // 商户
        return (orderType === 0 && this.status === 1) || (orderType === 1 && (this.status === 2 || this.status === -2))
      } else {
        // 用户
        return (orderType === 1 && this.status === 1) || (orderType === 0 && (this.status === 2 || this.status === -2))
      }
    },
    payText() {
      const orderType = this.info.order.orderType
      if (this.isTrader) {
        // 商户
        if (orderType === 0 && this.status === 1) {
          return "我已付款"
        } else if (orderType === 1 && (this.status === 2 || this.status === -2)) {
          return "我已收款"
        }
      } else {
        // 用户
        if (orderType === 1 && this.status === 1) {
          return "我已付款"
        } else if (orderType === 0 && (this.status === 2 || this.status === -2)) {
          return "我已收款"
        }
      }
    },
    isAppeal() {
      const orderType = this.info.order.orderType
      if (orderType === 0) {
        // 卖单
        if (this.isTrader) {
          // 商户
          return this.status === -2 || this.status === 4
        } else {
          // 用户
          return this.status === -1 || this.status === 4
        }
      } else {
        // 买单
        if (this.isTrader) {
          // 商户
          return this.status === -1 || this.status === 4
        } else {
          // 用户
          return this.status === -2 || this.status === 4
        }
      }
    },
    warnTip() {
      if (this.isTrader) {
        return '如果你确认该用户存在欺作，请保存证据并申诉。'
      } else {
        return '如果你确认该商户存在欺作，请保存证据并申诉。'
      }
    },
    isCancel() {
      if (this.status !== -1) {
        return false
      }
      const orderType = this.info.order.orderType
      if (orderType === 0) {
        // 卖单
        if (this.isTrader) {
          // 商户
          return this.status === -1
        } else {
          // 用户
        }
      } else {
        // 买单
        if (this.isTrader) {
          // 商户
        } else {
          // 用户
          return this.status === -1
        }
      }
      return false
    },
    status() {
      let status = this.info.order.status
      const time = new Date().getTime() / 1000
      let diffTime = this.endTime - time
      if ((status === 1 || status === 2) && diffTime <= 0) {
        status = status === 1 ? -1 : -2
      }
      return status
    },
    statusText() {
      const orderType = this.info.order.orderType
      const status = this.status
      // 买单
      let arr = {
        "-3": "--",
        "-2": "订单超时，待商户收款",
        "-1": "订单超时，待用户取消",
        "0": "用户已取消",
        "1": "待用户付款",
        "2": "待商户收款",
        "3": "订单已完成",
        "4": "订单已冻结",
      }
      if (orderType === 0) {
        // 卖单
        arr = {
          "-3": "--",
          "-2": "订单超时，待用户收款",
          "-1": "订单超时，待商户取消",
          "0": "商户已取消",
          "1": "待商户付款",
          "2": "待用户收款",
          "3": "订单已完成",
          "4": "订单已冻结",
        }
      }

      return arr[status]
    }
  },
  data() {
    return {
      appealShow: false,
      account: "",
      id: "",
      orderId: "",
      source: "user",
      infoLoading: false,
      info: {
        ad: {},
        order: {
          amount: new BigNumber(0),
          price: new BigNumber(0),
          traderAddress: "",
          owner: "",
          status: -2,
        },
        timeOut: 0
      },
      httpInfo: {
        PayMold: {},
        SessionId: "",
      },
      usdtDecimals: new BigNumber(1e18),
      cancelLoading: false,
      payLoading: false,
      complaintLoaing: false,
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.source = this.$route.query.source || "user"
    this.getHttpInfo()
    this.getAuthorizeInfo().then()
  },
  methods: {
    clearTimer() {
      if (this.info.order.status === 1) {
        this.info.order.status = -1
      } else if (this.info.order.status === 2){
        this.info.order.status = -2
      }
    },
    async getAuthorizeInfo() {
      const accounts = await getAccounts()
      this.account = accounts[0]
    },
    async getInfo() {
      this.infoLoading = true
      const info = await OrderInfo(this.httpInfo.Mold, this.httpInfo.AdId, this.orderId)
      this.infoLoading = false
      console.log(info)
      this.info = info
      if (this.info.order.status === 1 || this.info.order.status === 2) {
        this.$refs.myCountDown.init()
      } else if (this.$refs.myCountDown){
        this.$refs.myCountDown.clear()
      }
    },
    getHttpInfo() {
      OrderDetails({
        OrderId: this.orderId
      })
          .then(res => {
            if (res.status > 1) {
              return false
            }
            this.httpInfo = res.data
            this.getInfo().then()
          })
    },
    reOrderClick() {
      this.$router.push(`/advertise-detail?adId=${this.info.order.adId}`);
    },
    buyClick() {
      const orderType = this.info.order.orderType
      if (this.isTrader) {
        // 商户
        // return orderType === 0 ? "继续购买" : "继续出售"
        if(orderType === 0) {
          this.$router.push(`/advertise-detail?adId=${this.info.order.adId}`);
        } else {
          this.$router.push(`/advertise-create`);
        }
      } else {
        // 用户
        // return orderType === 0 ? "继续出售" : "继续购买"
        if(orderType === 0) {
          this.$router.push(`/advertise-create`);
        } else {
          this.$router.push(`/advertise-detail?adId=${this.info.order.adId}`);
        }
      }
    },
    async cancel() {
      console.log("取消订单")
      this.cancelLoading = true
      const res = await CancelOrder(this.httpInfo.Mold, this.info.order.id)
      this.cancelLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      const hash = res.transactionHash
      if (this.isTrader) {
        MerchantCancelOrder({
          CancelHash: hash,
          Protocol: this.protocol,
        }).then()
      } else {
        MemberCancelOrder({
          CancelHash: hash,
          Protocol: this.protocol,
        }).then()
      }

      this.$notify({
        type: 'success',
        message: '取消成功',
        duration: 2000,
        position: 'top-left',
      });

      this.getHttpInfo()
    },
    async payClick() {
      const orderType = this.info.order.orderType
      if (this.isTrader) {
        // 商户
        if (orderType === 0 && this.status === 1) {
          this.payLoading = true
          await this.confirmPay()
          this.payLoading = false
        } else if (orderType === 1 && (this.status === 2 || this.status === -2)) {
          this.$dialog.confirm({
            title: '温馨提示',
            message: '确认收款后,合约将自动释放您托管的代币,请确保已收到汇款后再确认收款,以免造成不必要的损失!',
          })
            .then(async () => {
              this.payLoading = true
              await this.confirmReceive()
              this.payLoading = false
            })
            .catch(() => {

            })
        }
      } else {
        // 用户
        if (orderType === 1 && this.status === 1) {
          this.payLoading = true
          await this.confirmPay()
          this.payLoading = false
        } else if (orderType === 0 && (this.status === 2 || this.status === -2)) {
          this.$dialog.confirm({
            title: '温馨提示',
            message: '确认收款后,合约将自动释放您托管的代币,请确保已收到汇款后再确认收款,以免造成不必要的损失!',
          })
              .then(async () => {
                this.payLoading = true
                await this.confirmReceive()
                this.payLoading = false
              })
              .catch(() => {

              })
        }
      }
    },
    async confirmPay() {
      const res = await ConfirmPay(this.httpInfo.Mold, this.info.order.id)
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      const hash = res.transactionHash
      const data = {
        Address: this.account,
        OrderId: this.info.order.id,
        Hash: hash,
        Status: 1,
      }
      if (this.isTrader) {
        // 商户
        MerchantCollection(data).then()
      } else {
        // 用户
        MembersCollection(data).then()
      }

      this.$notify({
        type: 'success',
        message: '付款成功',
        duration: 2000,
        position: 'top-left',
      });

      this.getHttpInfo()
    },
    async confirmReceive() {
      const res = await ConfirmReceive(this.httpInfo.Mold, this.info.order.id)
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      const hash = res.transactionHash
      const data = {
        Address: this.account,
        OrderId: this.info.order.id,
        Hash: hash,
        Status: 1,
      }
      if (this.isTrader) {
        // 商户
        MerchantTransfer(data).then()
      } else {
        // 用户
        MembersTransfer(data).then()
      }

      this.$notify({
        type: 'success',
        message: '收款成功',
        duration: 2000,
        position: 'top-left',
      });

      this.getHttpInfo()
    },
    async complaintOrder() {
      this.complaintLoaing = true
      const res = await ComplaintOrder(this.httpInfo.Mold, this.info.order.id)
      this.complaintLoaing = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }


      this.$notify({
        type: 'success',
        message: '申诉成功',
        duration: 2000,
        position: 'top-left',
      });

      this.appealShow = true

      const hash = res.transactionHash
      const data = {
        CancelHash: hash,
        Protocol: this.protocol,
      }
      if (this.isTrader) {
        // 商户
        this.MerchantComplaintOrder().then()
      } else {
        // 用户
        MemberComplaintOrder(data).then()
      }

      this.getHttpInfo()
    },
    messageClick() {
      if (!isApp()) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '请在CoinSafe钱包App中使用密语功能, 若还未安装,请点击下载 !',
        }).then(() => {
          window.open(this.systemSetting.AndroidDownload)
        })
        return false
      }
      if (this.httpInfo.SessionId.length === 0) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '当前商家没有提供联系方式',
        })
        return false
      }
      // 打开聊天
      sessionChat(this.httpInfo.SessionId)
    },
    sessionClick() {
      if (!isApp()) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '请在CoinSafe钱包App中使用密语功能, 若还未安装,请点击下载 !',
        }).then(() => {
          window.open(this.systemSetting.AndroidDownload)
        })
        return false
      }

      // 打开聊天
      sessionChat(this.systemSetting.SessionId)
    }
  },
};
</script>
<style scoped>

.box {
  background-color: #F9F9F9;
}

.header-warn-tip-box {
  padding: 30px 40px 20px;
}

.body {
  background-color: #FFFFFF;
  border-radius: 50px 50px 0 0;
  padding-top: 60px;
}

.order-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-header-title {
  color: #333333;
  font-size: 40px;
  font-weight: 800;
}

.order-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-header-message {
  width: 28px;
  height: 28px;
  margin-left: 20px;
}

.order-info {
  margin-top: 30px;
  padding: 0 40px;
}

.order-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 30px;
  border-bottom: 1px solid #E7E7E7;
}

.order-info-label {
  color: #666666;
  font-size: 24px;
  margin-right: 26px;
}

.order-info-content {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 24px;
  font-weight: 800;
}

.order-info-content-money {
  color: #779CDC;
  font-size: 32px;
  font-weight: 800;
}

.order-info-content-remark-text-overflow {
  color: #779CDC;
}

.center-warn-tip {
  padding: 90px 30px 50px;
}

.center-btn-box {
  padding: 86px 0 50px;
  display: flex;
  justify-content: center;
}

.center-btn {
  width: 420px;
  height: 80px;
  font-size: 30px;
  background-color: #3665B7;
  border-radius: 10px;
}

.center-btn-plain {
  background-color: #FFFFFF;
  border: 1px solid #3665B7;
}

.center-btn242 {
  width: 242px;
}

.center-btn400 {
  width: 400px;
}

.center-btn-m27 {
  margin-right: 27px;
}

.appeal-box {
  padding: 0 48px 32px;
}

.appeal-item {
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  color: #3D3D3D;
}

.appeal-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appeal-item-content-link {
  color: #409eff;
  text-decoration:underline;
  margin-right: 15px;
}

</style>

<style>
.order-info-content-remark {
  color: #666666;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 24px;
}
</style>
