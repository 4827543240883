import {getOTCAddress, getTraderAddress, getUSDTAddress} from "../utils/addressHelpers";

import OTCAbi from "../config/abi/OTC.json"
import TraderAbi from "../config/abi/Trader.json"
import Erc20Abi from "../config/abi/erc20.json"
import {multicallAllv2} from "../utils/multicall";
import {getOTCContract, getTraderContract} from "../utils/contractHelpers";
import {MAX_INT} from "../config";
import BigNumber from "bignumber.js";

export const CreateInfo = async (token, account) => {

    const otcAddress = getOTCAddress()

    const calls = [
        {
            address: token,
            name: "allowance",
            params: [account,otcAddress],
            abiIndex: 0,
        },
        {
            address: token,
            name: "balanceOf",
            params: [account],
            abiIndex: 0,
        },
        {
            address: otcAddress,
            name: "traderMaxAdCount",
            params: [],
            abiIndex: 1,
        },
        {
            address: otcAddress,
            name: "getTraderAdList",
            params: [account],
            abiIndex: 1,
        }
    ]

    const abis = [
        Erc20Abi,
        OTCAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)
    const max = new BigNumber(MAX_INT)
    const list = raw[3][0].map(item => item.toNumber())
    return {
        approve: new BigNumber(raw[0].toString()).comparedTo(max) >= 0,
        balance: new BigNumber(raw[1].toString()),
        traderMaxAdCount: raw[2][0].toNumber(),
        list: list,
    }
}

export const CreateAd = async (data) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.createAd(data.token, data.adType, data.amount, data.price, data.fiat, data.singleMin, data.singleMax, data.pay)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}

export const CreateOrderInfo = async (token, account, adAccount) => {

    const otcAddress = getOTCAddress()
    const traderAddress = getTraderAddress()
    const calls = [
        {
            address: token,
            name: "allowance",
            params: [account,otcAddress],
            abiIndex: 0,
        },
        {
            address: token,
            name: "balanceOf",
            params: [account],
            abiIndex: 0,
        },
        {
            address: otcAddress,
            name: "pause",
            params: [],
            abiIndex: 1,
        },
        {
            address: otcAddress,
            name: "userLockStatus",
            params: [account],
            abiIndex: 1,
        },
        {
            address: otcAddress,
            name: "userMaxOrderCount",
            params: [],
            abiIndex: 1,
        },
        {
            address: otcAddress,
            name: "getUserOrderLength",
            params: [account],
            abiIndex: 1,
        },
        {
            address: traderAddress,
            name: "getTraderStatus",
            params: [adAccount],
            abiIndex: 2,
        }
    ]

    const abis = [
        Erc20Abi,
        OTCAbi,
        TraderAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)
    const max = new BigNumber(MAX_INT)
    return {
        approve: new BigNumber(raw[0].toString()).comparedTo(max) >= 0,
        balance: new BigNumber(raw[1].toString()),
        pause: raw[2][0],
        userLockStatus: raw[3][0],
        userMaxOrderCount: raw[4][0],
        getUserOrderLength: raw[5][0].toNumber(),
        getTraderStatus: raw[6][0],
    }
}

export const CreateOrder = async (type,adId,amount) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.createOrder(type,adId,amount)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}

export const OrderInfo = async (type, adId, orderId) => {

    const adName = type === 0 ? 'buyAd' : 'sellAd'
    const orderName = type === 0 ? 'sellOrder' : 'buyOrder'
    const otcAddress = getOTCAddress()
    const calls = [
        {
            address: otcAddress,
            name: adName,
            params: [adId],
            abiIndex: 0,
        },
        {
            address: otcAddress,
            name: orderName,
            params: [orderId],
            abiIndex: 0,
        },
        {
            address: otcAddress,
            name: "timeOut",
            params: [],
            abiIndex: 0,
        },
    ]

    const abis = [
        OTCAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)
    // console.log(raw)

    return {
        ad: {
            id: raw[0].id.toNumber(),
            owner: raw[0].owner,
            token: raw[0].token,
            totalAmount: new BigNumber(raw[0].totalAmount.toString()),
            amount: new BigNumber(raw[0].amount.toString()),
            price: new BigNumber(raw[0].price.toString()),
            fiat: raw[0].fiat.toNumber(),
            singleMin: new BigNumber(raw[0].singleMin.toString()),
            singleMax: new BigNumber(raw[0].singleMax.toString()),
            timestamp: raw[0].timestamp.toNumber(),
            adType: raw[0].adType,
            endTimestamp: raw[0].endTimestamp.toNumber(),
            status: raw[0].status.toNumber(),
        },
        order: {
            id: raw[1].id.toNumber(),
            owner: raw[1].owner,
            tokenAddress: raw[1].tokenAddress,
            traderAddress: raw[1].traderAddress,
            nextAddress: raw[1].nextAddress,
            adId: raw[1].adId.toNumber(),
            amount: new BigNumber(raw[1].amount.toString()),
            price: new BigNumber(raw[1].price.toString()),
            fiat: raw[1].fiat.toNumber(),
            createTimestamp: raw[1].createTimestamp.toNumber(),
            orderType: raw[1].orderType,
            payTimestamp: raw[1].payTimestamp.toNumber(),
            endTimestamp: raw[1].endTimestamp.toNumber(),
            status: raw[1].status,
        },
        timeOut: raw[2][0].toNumber()
    }
}

export const CancelOrder = async (type,orderId) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.cancelOrder(type,orderId)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}

export const ConfirmPay = async (type,orderId) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.confirmPay(type,orderId)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}

export const ConfirmReceive = async (type,orderId) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.confirmReceive(type,orderId)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}

export const ComplaintOrder = async (type,orderId) => {

    const contract = getOTCContract(true)

    try {
        const tx = await contract.complaintOrder(type,orderId)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}