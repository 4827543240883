
export function isApp() {
    return window.app === "coin-safe"
}

/**
 * 获取APP里面的sessionId
 * @returns {Promise<*>}
 */
export async function getSessionId() {
    try {
        return await ethereum.request({method: "wallet_sessionId"})
    }catch (e) {
        return ""
    }
}

/**
 * 跳转聊天
 * @returns {Promise<*>}
 */
export async function sessionChat(sessionId) {
    // 判断是否在APP里面
    if (!isApp()) {
        return false
    }
    ethereum.request({method:"wallet_session_chat",
        params:[sessionId]}).then((result) =>{
        console.dir(result);
    }).catch((err)=>{
        console.dir(err);
    })

}