<template>
  <div class="my-copy" @click="copy($event)">
    <img class="my-copy-img" src="../assets/img/copy.png" alt="" />
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {
    text: {
      type: [String,Number],
      default: "",
    },
  },
  methods: {
    copy(event) {
      event = event || {};
      const clipboard = new Clipboard(event.target, {
        text: () => this.text || "",
      });
      clipboard.on("success", (e) => {
        this.$toast({
          duration: 3000,
          forbidClick: true,
          message: this.$t("copy-success"),
        })
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$toast({
          duration: 3000,
          forbidClick: true,
          message: this.$t("copy-error"),
        })
        clipboard.destroy();
      });
      clipboard.onClick(event);
    },
  },
};
</script>

<style scoped>
.my-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
}
.my-copy-img {
  width: 21px;
  height: 22px;
}
</style>
