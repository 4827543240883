<template>
  <span class="my-count-down">{{diffTime <= 0 ? '--' : text}}</span>
</template>

<script>
function format0(str) {
  return str.length <= 1 ? "0" + str : str
}
export default {
  name: "index",
  props: {
    type: {
      type: String,
      default: "h", // d：天，h：时，m：分，s：秒
    },
    endTime: {
      type: Number,
      default: 0
    }
  },
  computed: {
    text() {
      // console.log([this.type])
      let arr = []
      switch (this.type) {
        case 'd':
          arr.push(this.day)
          arr.push(this.hour)
          arr.push(this.minute)
          arr.push(this.second)
          break
        case 'h':
          arr.push(this.hour)
          arr.push(this.minute)
          arr.push(this.second)
          break
        case 'm':
          arr.push(this.minute)
          arr.push(this.second)
          break
        case 's':
          arr.push(this.second)
          break
      }
      return arr.join(":")
    }
  },
  data() {
    return {
      day: "00",
      hour: "00",
      minute: "00",
      second: "00",
      diffTime: -1,
      timer: null,
      clearStatus: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.timer) {
        return false
      }
      this.time = new Date().getTime() / 1000
      this.timer = setInterval(() => {
        this.time++
        let diffTime = this.endTime - this.time
        this.diffTime = diffTime <= 0 ? 0 : diffTime
        if (!this.clearStatus && !isNaN(this.diffTime) && this.diffTime > 0) {
          this.setTimeStr()
        } else {
          this.diffTime = 0
          clearInterval(this.timer)
          this.timer = null
          this.$emit("clearTimer")
        }
      }, 1000)
    },
    clear() {
      this.clearStatus = true
    },
    setTimeStr() {
      if (this.type === "day") {
        this.day = format0(Math.floor(this.diffTime / 86400).toString());
        this.hour = format0(Math.floor(this.diffTime % 86400 / 3600).toString());
        this.minute = format0(Math.floor(this.diffTime % 86400 % 3600 / 60).toString());
        this.second = format0(Math.floor(this.diffTime % 86400 % 3600 % 60).toString());
      } else if (this.type === 'h'){
        this.hour = format0(Math.floor(this.diffTime / 3600).toString());
        this.minute = format0(Math.floor(this.diffTime % 86400 % 3600 / 60).toString());
        this.second = format0(Math.floor(this.diffTime % 86400 % 3600 % 60).toString());
      } else if (this.type === 'm'){
        this.minute = format0(Math.floor(this.diffTime / 60).toString());
        this.second = format0(Math.floor(this.diffTime % 86400 % 3600 % 60).toString());
      } else {
        this.second = format0(Math.floor(this.diffTime).toString());
      }
    }
  }
}
</script>

<style scoped>
.my-count-down {
  color: #3665B7;
  font-size: 24px;
}
</style>